<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.lesson_wise_trainer_assign') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="expertise-trainers" :class="'btn btn-success text-light'">{{ $t('elearning_tim.lesson_wise_trainer_assign') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loader">
                      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                          <b-row>
                            <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization Type" vid="profession_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="profession_type"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_config.organization_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="data.profession_type"
                                  :options="orgTypeList"
                                  id="profession_type"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="data.profession_type === 1">
                          <ValidationProvider name="Organization" vid="resource_org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="resource_org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="data.resource_org_id"
                                  :options="orgList"
                                  id="resource_org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="resource_org_id"
                                      v-model="data.resource_org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                            <ValidationProvider name="Trainee Name" vid="personal_info_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="personal_info_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_tim.trainer_name') }} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                    plain
                                    v-model="data.personal_info_id"
                                    :options="trainerList"
                                    id="personal_info_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ trainerLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select> -->
                                  <v-select
                                      id="personal_info_id"
                                      v-model="data.personal_info_id"
                                      :reduce="op => op.value"
                                      :options="trainerList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                      <div id="form" >
                        <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.entry')}}</legend>
                      <div class="p-2">
                        <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                          <!-- <b-row class="p-4">
                            <b-col style="background-color: var(--gray-eee);" lg="6" class="offset-lg-3 p-2">
                              <template>
                                <h6 style="font-weight: bold;" class="text-sm-center">{{ $t('elearning_tim.expertise_trainer') }} {{ $t('globalTrans.details') }}</h6>
                              </template>
                            </b-col>
                          </b-row> -->
                          <b-row>
                            <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1" >
                              <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.organization')}}
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="data.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="org_id"
                                      v-model="data.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.training_type_id"
                                    :options="trainingTypeList"
                                    id="training_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_type_id"
                                      v-model="detailsFormData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.training_category_id"
                                    :options="trainingCategoryList"
                                    id="training_category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_category_id"
                                      v-model="detailsFormData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_title_id"
                                      v-model="detailsFormData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Course Module"  vid="course_module_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="course_module_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.course_module')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.course_module_id"
                                    :options="courseModuleList"
                                    id="course_module_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="course_module_id"
                                      v-model="detailsFormData.course_module_id"
                                      :reduce="op => op.value"
                                      :options="courseModuleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Course"  vid="course_management_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="course_management_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_tim.lesson')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.course_management_id"
                                    :options="courseList"
                                    id="course_management_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="course_management_id"
                                      v-model="detailsFormData.course_management_id"
                                      :reduce="op => op.value"
                                      :options="courseList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                      </div>
                    </fieldset>
                      </div>
                      <br/>
                    <!-- List  -->
                    <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.list')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
                        <thead class="thead">
                          <tr>
                            <th style="width:6%">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="width:17%">{{$t('elearning_config.training_type')}}</th>
                            <th style="width:17%">{{$t('elearning_config.training_category')}}</th>
                            <th style="width:20%">{{$t('elearning_config.training_title')}} </th>
                            <th style="width:20%">{{$t('elearning_config.course_module')}} </th>
                            <th style="width:20%">{{$t('elearning_tim.lesson')}} </th>
                            <th style="width:8%">{{ $t('globalTrans.action') }}</th>
                          </tr>
                        </thead>
                        <tr v-for="(item, index) in data.details" :key="index">
                          <b-td>
                            {{ $n(index + 1) }}
                          </b-td>
                          <b-td>
                            {{ getTrainingTypeName(item.training_type_id) }}
                          </b-td>
                          <b-td>
                            {{ getTrainingCatName(item.training_category_id) }}
                          </b-td>
                          <b-td>
                            {{ getTrainingTitleName(item.training_title_id) }}
                          </b-td>
                          <b-td>
                            {{ getCourseModuleName(item.course_module_id) }}
                          </b-td>
                          <b-td>
                            {{ getCourseName(item.course_management_id) }}
                          </b-td>
                          <b-td>
                              <span class="d-flex text-center">
                                <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                              </span>
                            </b-td>
                        </tr>
                        <b-tr v-if="data.details.length <= 0">
                              <td colspan="8" class="text-center">
                                  <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                              </td>
                        </b-tr>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
                    <b-row class="text-right">
                      <b-col>
                        <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm"> {{ saveBtnName }}</b-button>
                        <!-- <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button> -->
                        <!-- <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button> -->
                        <!-- <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button> -->
                        <router-link to="expertise-trainers" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                      </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
          </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { allTrainerData, expertiseTrainerShow, expertiseTrainerUpdate, expertiseTrainerStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.id) {
      this.getExpertiseTrainerData(this.$route.query.id)
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.data.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      circularLoading: false,
      trainerLoading: false,
      valid: null,
      // loading: false,
      loader: false,
      loadingCourseModule: false,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        profession_type: 1,
        resource_org_id: null,
        personal_info_id: null,
        org_id: null,
        details: []
      },
      detailsFormData: {
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        course_module_id: null,
        course_management_id: null
      },
      editIndex: '',
      officeTypeList: [],
      trainers: [],
      officeList: [],
      courseModuleList: [],
      courseList: [],
      trainerListData: [],
      trainingTypeList: [],
      trainingCategoryList: [],
      course_module_errors: [],
      circularList: [],
      circularErrorMsg: false,
      courseError: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    jobTypeList () {
      return this.$store.state.TrainingElearning.commonObj.jobTypeList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'data.profession_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 2 || newVal === 3) {
          this.data.resource_org_id = null
          this.trainerListData = []
          this.getOrgTrainerList()
        } else {
          if (this.data.resource_org_id) {
            this.getOrgTrainerList()
          }
        }
      }
    },
    'data.resource_org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrgTrainerList()
        // this.trainingTypeList = this.getTypeList(newVal)
      }
    },
    'data.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTypeList = this.getTypeList(newVal)
      } else {
        this.trainingTypeList = []
      }
    },
    'detailsFormData.training_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingCategoryList = this.getCategoryList(newVal)
      } else {
        this.trainingCategoryList = []
      }
    },
    'detailsFormData.training_category_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTitleList = this.getTrainingTitleList(newVal)
      } else {
        this.trainingTitleList = []
      }
    },
    'detailsFormData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.courseModuleList = this.getCourseModuleList(newVal)
      } else {
        this.courseModuleList = []
      }
    },
    'detailsFormData.course_module_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.courseList = this.getCourseList(newVal)
      } else {
        this.courseList = []
      }
    }
  },
  methods: {
    getTrainingTypeName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
    },
    getTrainingTitleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingCatName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseModuleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    cancelItem () {
        this.detailsFormData = {}
        this.editIndex = ''
        this.$refs.form1.reset()
    },
    editItem (item, index) {
        this.detailsFormData = Object.assign({}, item)
        this.editIndex = index
    },
    deleteItem (index) {
        this.data.details.splice(index, 1)
    },
    async addItem () {
      let result = []
      if (this.editIndex !== '') {
          const editList = [...this.data.details]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
      } else {
          result = this.data.details
      }
      const trainingTypeId = this.detailsFormData.training_type_id
      const trainingCatId = this.detailsFormData.training_category_id
      const trainingTitleId = this.detailsFormData.training_title_id
      const courseModuleId = this.detailsFormData.course_module_id
      const courseManagementId = this.detailsFormData.course_management_id

      const newData = result.find(item => item.training_type_id === trainingTypeId && item.training_category_id === trainingCatId && item.training_title_id === trainingTitleId && item.course_module_id === courseModuleId && item.course_management_id === courseManagementId)
      if (typeof newData === 'undefined') {
          if (this.editIndex !== '') {
              this.data.details[parseInt(this.editIndex)].training_type_id = this.detailsFormData.training_type_id
              this.data.details[parseInt(this.editIndex)].training_category_id = this.detailsFormData.training_category_id
              this.data.details[parseInt(this.editIndex)].training_title_id = this.detailsFormData.training_title_id
              this.data.details[parseInt(this.editIndex)].course_module_id = this.detailsFormData.course_module_id
              this.data.details[parseInt(this.editIndex)].course_management_id = this.detailsFormData.course_management_id
          } else {
             this.data.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
          }
          this.detailsFormData = {
            org_id: this.detailsFormData.org_id,
            training_type_id: this.detailsFormData.training_type_id,
            training_category_id: this.detailsFormData.training_category_id,
            training_title_id: this.detailsFormData.training_title_id,
            course_module_id: 0,
            course_management_id: 0
          }
          this.editIndex = ''
      } else {
          this.$toast.error({
              title: '!',
              message: 'Item already added',
              color: '#ee5253'
          })
      }
      this.$nextTick(() => {
          this.$refs.form1.reset()
      })
    },
    async saveUpdate () {
      var check = await this.$refs.form.validate()
      if (check) {
        if (!this.data.details.length) {
          return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Add at least one item!',
            color: 'red'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        this.loader = true
        // this.data.flag = this.flag_status
        const loadingState = { loading: false, listReload: false }
        if (this.data.id) {
          result = await RestApi.putData(trainingElearningServiceBaseUrl, `${expertiseTrainerUpdate}/${this.data.id}`, this.data)
        } else {
          result = await RestApi.postData(trainingElearningServiceBaseUrl, expertiseTrainerStore, this.data)
        }
        this.loader = false
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.data.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push('/training-e-learning-service/tim/expertise-trainers')
          // this.$bvModal.hide('modal-form')
          } else {
            this.$toast.error({
            message: this.data.id ? this.$t('elearning_tim.lesson_wise_speaker_assign') : this.$t('elearning_tim.lesson_wise_speaker_assign'),
            color: '#D6E09B'
          })
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    async getOrgTrainerList () {
      this.trainerLoading = true
      let orgiId = 0
      if (this.$store.state.Auth.activeRoleId !== 1) {
        orgiId = this.$store.state.Auth.authUser.org_id
      }
      const params = {
        org_id: this.data.resource_org_id,
        profession_type: this.data.profession_type,
        applied_org_id: orgiId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
      if (!result.success) {
        this.trainerListData = []
      } else {
        this.trainerListData = result.data
      }
      this.trainerLoading = false
    },
    // async getOrgTrainerList () {
    //   this.trainerLoading = true
    //   const params = {
    //     org_id: this.data.resource_org_id,
    //     profession_type: this.data.profession_type
    //   }
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
    //   if (!result.success) {
    //     this.trainerListData = []
    //   } else {
    //     this.trainerListData = result.data
    //   }
    //   this.trainerLoading = false
    // },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
    },
    getCourseList (typeId) {
       const courseList = this.$store.state.TrainingElearning.commonObj.courseList.filter(item => item.status === 1)
       if (typeId) {
         return courseList.filter(item => item.course_module_id === typeId)
       }
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
    },
    getCourseModuleList (trainingTitleId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
    },
    async getExpertiseTrainerData (id) {
        this.loader = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, expertiseTrainerShow + '/' + id)
        if (result.success) {
          // this.data = Object.assign({}, this.formData, result.data)
          this.data = result.data
          this.loader = false
        } else {
          this.loader = false
        }
      }
  }
}
</script>
